import React, { useEffect, useState } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RawDraftContentState } from 'draft-js';
import api from '../../../../api/axiosapi';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Article } from '../../../../types/Article.type';
import EditorComponent from '../../components/EditorComponent';
import { useInitialArticleState } from '../../../../hooks/UseInitialArticleState';
import CategorySelector from '../../components/CategorySelector';
import FileSelector from '../../components/FileSelector';
import { FileModel } from '../../../../types/File.type';
import { submitArticle } from './SubmitArticle';

export type Category = {
    name: string;
    id: number;
}

function DashboardOffers() {

    const navigate = useNavigate();

    const [currentArticle, setCurrentArticle] = useState<Article>(useInitialArticleState());
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const { id } = useParams<{id: string}>();

    function handleTitleChange(event: React.FormEvent<HTMLInputElement>)
    {
        setCurrentArticle(previous => {
            return {...previous, title: event.currentTarget.value}
        });
    }

    function handleBackgroundImageUrlChange(event: React.FormEvent<HTMLInputElement>)
    {
        setCurrentArticle(previous => {
            return {...previous, backgroundImage: event.currentTarget.value}
        });
    }

    async function handleSubmit()
    {
        if(!currentArticle.title) {
            toast.error("L'article doit être doté d'un nom")
            return;
        }
        if(selectedCategories.length < 1) {
            toast.error("Au moins une catégorie doit-être sélectionnée.");
            return;
        }

        // Check le body de l'article
        
        if (await submitArticle(currentArticle, selectedCategories))
            navigate('/dashboard');
    }

    useEffect(() => {

        if (!id)
            return ;
        api.get<Article>(`/article/get/${id}`).then((response) => {
            setCurrentArticle(response.data);
            setSelectedCategories(response.data.categories);
            console.log(response.data);
        }).catch((error) => {
            toast.error('Impossible de récupérer cet article.');
            console.log(error);
            navigate('/dashboard');
        });
    }, [id, navigate]);

    useEffect(() => {

    }, [currentArticle.files]);

  return (
    <div className='w-full bg-white p-10 flex flex-col gap-10 rounded-lg'>
        <div className='flex flex-col gap-1'>
            <h1 className='text-xl font-semibold text-[#0b1e5b]'>Création d'article</h1>
        </div>

        <div className='flex flex-col gap-2'>
            <h2 className='text-md font-semibold text-[#0b1e5b]'>Titre de l'article</h2>
            <div className='bg-[#f8f8f8] p-1 rounded-lg'><input className='bg-[#f8f8f8] w-full p-2 outline-none text-md' type="text" name="" id="" required placeholder="Réduction chez cdiscount!" onChange={handleTitleChange} value={currentArticle.title} /></div>
        </div>

        <div className='flex flex-col gap-2'>
            <h2 className='text-md font-semibold text-[#0b1e5b]'>Image de référence</h2>
            <div className='bg-[#f8f8f8] p-1 rounded-lg'><input className='bg-[#f8f8f8] w-full p-2 outline-none text-md' type="text" name="" id="" required placeholder="Un lien vers une image..." onChange={handleBackgroundImageUrlChange} value={currentArticle.backgroundImage} /></div>
        </div>

        <CategorySelector selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories}/>

        <div className='h-fit'>
            <h2 className='text-md font-semibold text-[#0b1e5b]'>Contenu</h2>
            <EditorComponent
                onChange={(content: RawDraftContentState) => {
                    setCurrentArticle(previous => {
                        return {...previous, body: JSON.stringify(content)}
                    });
                }}

                value={currentArticle.id === 0 ? "" : currentArticle.body}
            />
        </div>

        <FileSelector initialFiles={currentArticle.files} 
            onFileChange={(changedFile: FileModel) => {
                const fileIndex = currentArticle.files.findIndex(file => file.name === changedFile.name);
                const updatedFiles = [...currentArticle.files];

                if (fileIndex !== -1)
                {
                    changedFile.toDelete = true;
                    updatedFiles[fileIndex] = changedFile;
                }
                else
                    updatedFiles.push(changedFile);

                setCurrentArticle({...currentArticle, files: updatedFiles});
            }}
        />

        <div>
            <button className='p-2 bg-[#1481e8] text-white font-semibold rounded-lg px-5 transition-all hover:-rotate-[2deg]' onClick={handleSubmit}>Sauvegarder</button>
        </div>
    </div>
  )
}

export default DashboardOffers