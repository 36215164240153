import React from 'react'

interface DataCardProps {
    value: string;
    label: string
    primaryColor?: string;
    secondaryColor?: string;
    icon: string;
}

function DataCard(props: DataCardProps) {
  return (
    <div data-aos="fade-left" className='flex p-7 bg-white rounded-xl gap-6 px-8 w-[300px] transition-all cursor-pointer shadow-sm hover:rotate-[90deg]'>
        <div className={`flex items-center justify-center w-16 h-16 rounded-xl bg-[${props.secondaryColor}] rounded-full`}>
            <i className={`fa-solid fa-xl ${props.icon} text-[${props.primaryColor}]`}></i>
        </div>
        <div className='flex flex-col gap-2'>
            <span className='text-[#003049] font-bold text-xl'>{props.value}</span>
            <span className='text-[#9ca3b7] font-light'>{props.label}</span>
        </div>
    </div>
  )
}

export default DataCard