
import { useState } from 'react'
import { Link } from 'react-router-dom';

interface SidebarElementProps {
	icon: string;
	label: string;
	to?: string;
	active?: boolean;
}

function SidebarElement(props: SidebarElementProps) {

	return (
		<li className='w-full px-[6px]'>
			<Link to={props.to ?? ''}>
				<div className={`flex flex-start items-center overflow-hidden flex-nowrap rounded-lg w-full ${props.active ? 'bg-[#1181e8] text-white' : 'text-[#868fa7]'} hover:bg-[#1181e8] hover:text-white cursor-pointer transition-all duration-400`}>
					<span className='w-12 h-12 shrink-0 flex items-center justify-center'><i className={`fa-solid ${props.icon}`}></i></span>
					<span className=''>{props.label}</span>
				</div>
			</Link>
		</li>
	);
}

const links = [
	{
		icon: 'fa-home',
		label: 'Accueil',
		to: '/'
	},
	{
		icon: 'fa-gauge',
		label: 'Dashboard',
		to: '/dashboard'
	},
	{
		icon: 'fa-signs-post',
		label: 'Offres',
		to: '/dashboard/offers'
	},
	{
		icon: 'fa-gear',
		label: 'Paramètres',
		to: '/dashboard/'
	},
	{
		icon: 'fa-right-from-bracket',
		label: 'Déconnexion',
		to: '/logout'
	},
]

interface SidebarProps {
	activeElement?: string;
}

function Sidebar(props: SidebarProps) {

	const OPEN_WIDTH = "180";
	const CLOSE_WIDTH = "75";

	const [open, setOpen] = useState(false);
	
	return (
		<div className={`relative h-full w-[${CLOSE_WIDTH}px]`}>
			<div className={`w-[${open ? OPEN_WIDTH : CLOSE_WIDTH}px] absolute z-10 transition-all h-full bg-white flex flex-col items-center gap-5 p-2 relative shadow-sm`}>
				<div className='mt-2'>
					<img src="/logo-intercos37.png" alt="" className='w-10'/>
				</div>
				<div className='w-full'>
					<ul className='flex flex-col gap-3 relative'>
						{links.map((element, index) => {
							return <SidebarElement icon={element.icon} label={element.label} active={props.activeElement ? element.label === props.activeElement : index < 1} to={element.to} key={index}/>
						})}
					</ul>
				</div>
				<div className='absolute cursor-pointer -right-5 w-10 h-10 bg-white top-1/2 text-[#868fa7] rounded-full flex items-center justify-center'
					onClick={() => setOpen(!open)}
				>
					<i className={`fa-solid fa-chevron-right transition-all ${open ? 'rotate-180' : 'rotate-0'}`}></i>
				</div>
			</div>
		</div>
	)
}

export default Sidebar