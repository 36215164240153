import React from 'react'

interface AboutCardsProps
{
	color: string;
	secondColor: string;
	faicon: string;
	title: string;
	desc: string;
	odd: boolean;
}

function AboutCards(props: AboutCardsProps) {
  return (
	<div data-aos={`${props.odd ? 'fade-right' : 'fade-left'}`} className={`flex flex-col items-center justify-center gap-10`}>
		<span className={`flex items-center justify-center w-32 h-32 bg-[${props.color}] rounded-full relative`}>
			<i className={`${props.faicon} fa-3x text-white z-10`}></i>
			<div className={`w-36 h-[72px] rounded-bl-full rounded-br-full bg-[${props.secondColor}] absolute -rotate-[60deg] left-5 top-11`}></div>
		</span>
		<div className='flex flex-col items-center justify-center text-[#828c96]'>
			<span className='font-semibold text-[#062036] text-lg text-center w-64'>{props.title}</span>
			<span className='md:hidden'>{props.desc}</span>
		</div>
	</div>
  )
}

export default AboutCards