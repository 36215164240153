import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import api from '../../../api/axiosapi';

type Article = {
	id: number;
	authorId: number;
	body: string;
	title: string;
	partnerId: number;
	createdAt: Date;
	modifiedAt: Date;
}

function LastArticles() {

	const [data, setData] = useState<Article[]>([]);

	const handleDeleteClick = (article: Article) => {
		console.log(`Delete article ${article.id}`);
		api.get<Article>(`/article/delete/${article.id}`).then((response) => {
			if(response.status === 200)
			{
				setData(previous => previous.filter(item => item.id !== article.id));
				toast.success("Article supprimé !");
				//TODO: Faire un truc pour annuler dans le toast ?
			}
		});
	};

	useEffect(() => {
		api.get<Article[]>('/article/getall').then((response) => {
			setData(response.data);
		});
	}, []);

  return (
	<div className='w-full bg-white p-5 rounded-lg flex flex-col gap-5'>
		<div className='flex items-center justify-between gap-10'>
			<h3 className='text-md font-semibold text-[#072e41]'>Articles récents</h3>
			<span><i className="fa-solid fa-ellipsis"></i></span>
		</div>
		<div>
			{ data.length > 0 ? 
			<table className='w-full table'>
				<thead className='h-10'>
					<tr className='text-[#a2a6b4] text-sm text-left'>
						<th className='w-10'>N°</th>
						<th>Titre</th>
						<th className='md:text-center'>Action</th>
					</tr>
				</thead>
				<tbody>
					{data.map((element, index) => {
						return (
							<tr className='text-[#9fa3b1] h-10' key={index}>
								<td><span className='w-6 h-6 text-white bg-[#0d81e7] flex items-center justify-center text-xs rounded'>{element.id}</span></td>
								<td className='text-sm'>{element.title}</td>
								<td className='text-center flex justify-center relative'>
									<span className='group flex justify-center items-center bg-[#f8f8f8] w-10 h-10 rounded-full text-center cursor-pointer relative'>
										<i className="fa-solid fa-ellipsis"></i>
										<div className='group-hover:flex hover:flex hidden absolute p-3 px-5 right-9  flex-col gap-2'>
											<div className='flex flex-col gap-2 rounded-xl bg-white shadow-xl p-3 text-[#0b1e5b]'>
												<Link to={`/dashboard/offers/update/${element.id}`}><span className='hover:bg-[#f8f8f8] p-2 px-3 rounded-xl'>Modifier</span></Link>
												<span className='hover:bg-[#f8f8f8] p-2 px-3 rounded-xl' onClick={() => handleDeleteClick(element)}>Supprimer</span>
											</div>
										</div>
									</span>
								</td>
							</tr>
						)
					})}
				</tbody>
			</table> : <div className='text-sm text-[#9fa3b1]'>Pas d'articles pour le moment</div> }
		</div>
	</div>
  )
}

export default LastArticles