import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';

interface DashboardProps
{
	children: React.ReactNode,
	sidebarActiveLabel?: string
}

function Dashboard(props: DashboardProps) {

	let location = useLocation();
	
	useEffect(() => {
		// If location changed then update main section to what you want
		// Dont forget to handle none desired routes like render to the main section with all stats
	}, [location]);

	return (
	<div className='w-full h-screen bg-[#f8f8f8] flex'>
		<Sidebar activeElement={props.sidebarActiveLabel} />
		<div className='w-full p-5 gap-5 flex flex-col items-center overflow-y-auto overflow-x-hidden'>
			<Navbar />
			{props.children}
		</div>
	</div>
	)
}

export default Dashboard