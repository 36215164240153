import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import privateApi from '../../../api/axiosapi';
import { Category } from '../pages/offer/DashboardOffers';
import Tag from './Tag';

interface CategorySelectorProps {
    selectedCategories: Category[],
    setSelectedCategories: React.Dispatch<React.SetStateAction<Category[]>>
}

function CategorySelector(props: CategorySelectorProps) {

    const [categories, setCategories] = useState<Category[]>([]);
    const [newCategoryName, setNewCategoryName] = useState<string>("");

    const [showCategoriesPopup, setShowCategoriesPopup] = useState<boolean>(false);
    const [showAddCategorySection, setShowAddCategorySection] = useState<boolean>(false);

    function handleCategoryAdd(category: Category) {
        props.setSelectedCategories((previous) => {
            if (previous.includes(category))
                return previous.filter((element) => element !== category);
            else
                return [...previous, category];
        });
        setShowCategoriesPopup(!showCategoriesPopup);
    }

    function handleNewCategoryNameChange(event: React.FormEvent<HTMLInputElement>) {
        setNewCategoryName(event.currentTarget.value);
    }

    function handleCategoryCreation()
    {
        if (newCategoryName === "")
        {
            toast.error("Merci de donner un nom à la catégorie.");
            return ;
        }

        privateApi.post<Category>("/categories/create", {
            name: newCategoryName
        }).then((response) => {
            if (response.status === 201) {
                toast.success("Catégorie créée !")
                setShowCategoriesPopup(false);
                setShowAddCategorySection(false);
                props.setSelectedCategories(previous => [...previous, response.data]);
            }
            else {
                console.log(response);
                toast.error("La catégorie existe déjà");
            }
        }).catch(() => {
            toast.error("Impossible de crééer la catégorie");
        }).finally(() => {
            setNewCategoryName("");
        });
    }

    useEffect(() => {
        privateApi.get<Category[]>('/categories/getall').then((response) => {
            setCategories(response.data);
        }).catch((reason) => {
            console.log(`Erreur lors de la récupération des catégories : ${reason}`);
            toast.error('Impossible de récupérer les catégories.');
        });
    }, [ ,showAddCategorySection]);

  return (
    <>
        <div className='flex flex-col gap-2'>
            <h2 className='text-md font-semibold text-[#0b1e5b]'>Catégories</h2>
            <div className='flex gap-2'>
                <button className='bg-[#1481e8] p-1 px-3 rounded-lg text-white flex flex-col gap-2 relative'>
                    <span className='flex gap-2 items-center cursor-pointer p-1' 
                        onClick={() => setShowCategoriesPopup(!showCategoriesPopup)}>
                            Ajouter<i className="fa-solid fa-circle-plus"></i>
                    </span>
                    <div className={`absolute bg-white left-28 z-10 -top-2 p-2 rounded-lg shadow-2xl text-[#0b1e5b] flex flex-col overflow-y-auto max-h-20 max-w-30 ${showCategoriesPopup ? 'flex' : 'hidden'}`}>
                        <span className='px-3 py-1 hover:bg-[#f0f1f4] rounded-lg flex items-center gap-1 justify-center' 
                            onClick={() => {setShowAddCategorySection(true); setShowCategoriesPopup(false)}}><i className="fa-solid fa-circle-plus"></i>Nouvelle</span>
                        {categories ?
                            categories.map((category, index) => {
                                return <span className='px-3 py-1 hover:bg-[#f0f1f4] rounded-lg' key={index} 
                                onClick={() => {handleCategoryAdd(category)}}>{category.name}</span>
                            }) : <span>No elements</span>
                        }
                    </div>
                </button>
                {props.selectedCategories.map((category) => {
                    return <Tag category={category} onClick={(categoryToDelete) => {
                        props.setSelectedCategories((previous) => previous.filter((category) => category !== categoryToDelete))}
                    } />;
                })}
            </div>
        </div>

        <div className={`flex flex-col gap-2 ${showAddCategorySection ? 'flex' : 'hidden'}`}>
            <h2 className='text-md font-semibold text-[#0b1e5b]'>Créer une catégorie</h2>
            <div className='flex flex-col gap-3'>
                <div className='bg-[#f8f8f8] p-1 rounded-lg'><input className='bg-[#f8f8f8] w-full p-2 outline-none text-md' type="text" name="" id="categ" placeholder="Nom de la catégorie" value={newCategoryName} onChange={handleNewCategoryNameChange}/></div>
                <div className=''>
                    <span className='p-2 bg-[#1481e8] text-white font-semibold rounded-lg px-5 transition-all hover:-rotate-[2deg] cursor-pointer w-auto' onClick={handleCategoryCreation}>Ajouter</span>
                </div>
            </div>
        </div>
    </>
  )
}

export default CategorySelector