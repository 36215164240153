import React, { useEffect, useRef, useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import { toast } from 'react-hot-toast';

interface EditorProps {
	onChange: Function,
	value: string,
}

function EditorComponent(props: EditorProps) {

	const [state, setState] = useState(EditorState.createEmpty());

	function handleEditorChange(state: EditorState) {
		setState(state);
	}

	useEffect(() => {
		if(props.value)
			setState(EditorState.createWithContent(convertFromRaw(JSON.parse(props.value))));
	}, [props.value]);

	useEffect(() => {
		const delay = setTimeout(() => {
			toast('Contenu mis à jour', {
				icon: 'ℹ️'
			});
			props.onChange(convertToRaw(state.getCurrentContent()));
		}, 2000);
		return () => clearTimeout(delay);
	}, [state]);

	return (
		<div>
			<Editor
				editorState={state}
				toolbarClassName={'toolbarClassName rounded-lg'}
				wrapperClassName={'wrapperClassName h-fit p-1 mt-2'}
				editorClassName={'editorClassName bg-[#f8f8f8] p-5 rounded-lg'}
				onEditorStateChange={handleEditorChange}

				toolbar={{
					options: ['inline', 'blockType', 'fontFamily', 'list', 'textAlign', 'image', 'emoji', 'colorPicker']
				}}
			/>
		</div>
	)
}

export default EditorComponent