import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import Swiper, { Pagination, Autoplay } from 'swiper';
import '../../assets/swiper.min.css'
import Slide from './components/Slide';
import { Helmet } from 'react-helmet';
import api from '../../api/axiosapi';

function Login() {

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    
    if(localStorage.getItem("access_token"))
        api.get('/auth/userinfo').then(() => {
            navigate('/dashboard')}
        ).catch(() => {
    }); //TODO: Do this action before render

    useEffect(() => {
        
        Swiper.use([Pagination, Autoplay]);
    
        const mySwiper = new Swiper('.swiper-container', {
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          autoplay: {
            delay: 5000, // Delay between auto-swiping in milliseconds
            disableOnInteraction: false, // Disable auto-swiping on user interaction
          },
        });
    
        return () => {
          mySwiper.destroy();
        };
    }, []);
    
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    };

    async function handleLogin(event: any) : Promise<void>
    {
        event.preventDefault();
        try {
            await api.post("/auth/local/signin", {
                email: formData.email,
                password: formData.password
            }).then((response) => {
                console.log(response);
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                toast.success("Connecté");
                navigate('/dashboard');
            }).catch((error) => {
                console.log(error);
                toast.error("Impossible de se connecter");
            });
        } catch (error) {
            console.log(error);
            toast.error("Impossible de se connecter")
        }
    }

  return (
    <>
    <Helmet>
        <title>Connexion - Intercos37</title>
        <meta name="description" content="Connectez-vous pour accéder à votre compte et consulter des données en tout genre." />
        <link rel="canonical" href="https://www.intercos37.fr/login" />
        <meta property="og:title" content="Connexion - Intercos37" />
        <meta property="og:description" content="Connectez-vous pour accéder à votre compte et consulter des données en tout genre." />
        <meta property="og:image" content="https://intercos37.fr/logo-intercos37.png" />
        <meta property="og:url" content="https://intercos37.fr/login" />
    </Helmet>
    <div className='w-full h-screen flex justify-center lg:justify-start items-center lg:p-2'>

        <div className='hidden lg:flex h-full relative overflow-hidden rounded-xl w-[6OOpx] shrink-0'>
            <div className="swiper-container w-[600px] relative">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <Slide image='/assets/blurry-gradient1.webp' 
                        title={"Profitez des offres de l'Intercos37 !"}
                        description={"Adhérez et profitez d'une large gamme d'offres et d'avantages chez nous."}
                        comment={"Le travail éloigne de nous trois grands maux : l'ennui, le vice et le besoin."}
                        author={"Voltaire (1764)"}
                        />
                    </div>
                    <div className="swiper-slide">
                        <Slide image='/assets/blurry-gradient2.webp' 
                        title={"Profitez des offres de l'Intercos37 !"} 
                        description={"Adhérez et profitez d'une large gamme d'offres et d'avantages chez nous."}
                        comment={"Le succès en affaires requiert une formation et une discipline. Mais si vous n'êtes pas effrayé par ces détails, les opportunités sont aussi vastes aujourd'hui qu'elles l'ont jamais été."}
                        author={"David Rockefeller (1960)"}
                        />
                    </div>
                    <div className="swiper-slide">
                        <Slide image='/assets/blurry-gradient3.webp' 
                        title={"Profitez des offres de l'Intercos37 !"}
                        description={"Adhérez et profitez d'une large gamme d'offres et d'avantages chez nous."}
                        comment={"Le travail acharné bat le talent quand le talent ne travaille pas dur."}
                        author={"Tim Notke (2007)"}
                        />
                    </div>
                </div>
                <div className="swiper-pagination"></div>
            </div>
        </div>

        <div className='p-10 flex gap-5 flex-col'>
            <h1 data-aos="fade-right" className='text-4xl font-semibold'>Hey, bon retour!</h1>
            <p data-aos="fade-right" data-aos-delay="100" className='text-[#a3a9b2] font-semibold text-sm'>Pour tous problèmes ou toutes demandes, merci de contacter l'administrateur.</p>
            
            <form onSubmit={handleLogin} className='flex flex-col gap-5'>
                <div data-aos="fade-right" data-aos-delay="200" className='flex bg-[#f6f7f9] p-2 rounded-xl gap-3 mt-10'>
                    <div className='w-10 h-10 rounded-xl bg-white flex items-center justify-center text-[#75a8ee]'>
                        <i className="fa-regular fa-envelope fa-lg"></i>
                    </div>
                    <input type="text" name="email" onChange={handleInputChange} id="" required placeholder='un.example@gmail.com' value={formData.email} className='bg-[#f6f7f9] font-medium text-[#b7bbc4] grow outline-none' />
                </div>

                <div data-aos="fade-right" data-aos-delay="200" className='flex bg-[#f6f7f9] p-2 rounded-xl justify-between'>
                    <div className='flex gap-3 grow w-full'>
                        <div className='w-10 h-10 rounded-xl bg-white flex items-center justify-center text-[#75a8ee]'>
                            <i className="fa-solid fa-lock"></i>
                        </div>
                        <input type={`${showPassword ? 'text' : 'password'}`} required name="password" onChange={handleInputChange} value={formData.password} id="" placeholder='Au moins 8 caractères' className='bg-[#f6f7f9] font-medium text-[#b7bbc4] grow outline-none' />
                    </div>
                    <div onClick={() => setShowPassword(!showPassword) } className={`flex items-center justify-center px-3 rounded-full cursor-pointer ${showPassword ? 'bg-[#b7bbc4] text-white' : 'text-[#58595d] bg-none'} transition-colors duration-500`}>
                        <i className="fa-solid fa-eye"></i>
                    </div>
                </div>
                <div data-aos="fade-right" data-aos-delay="300" className='flex justify-between px-2'>
                    <Link to={"/"}> 
                        <span className='text-[#147bff] font-semibold'>Accueil</span>
                    </Link>
                    <Link to={"/forgotten"}> 
                        <span className='text-[#147bff] font-semibold'>Mot de passe oublié?</span>
                    </Link>
                </div>
                <button type='submit' data-aos="fade-right" data-aos-delay="400" className='w-full cursor-pointer relative bg-[#147bff] p-3 rounded-xl text-center text-white font-medium'>
                    Se connecter
                </button>
            </form>
            <div data-aos="fade-right" data-aos-delay="500" className='w-full flex items-center justify-center text-[#b2b7bb] gap-1 text-sm font-medium mt-10'>
                <i className="fa-solid fa-copyright"></i>Intercos37 tous droits réservés
            </div>
        </div>

    </div>
    </>
  )
}

export default Login