import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { FileModel } from '../../../types/File.type';
import Attachment from './Attachment';

const MAX_FILE_COUNT = 3;

interface FileSelectorProps {
	initialFiles: FileModel[],
	onFileChange: (files: FileModel) => void;
}

function FileSelector(props: FileSelectorProps) {

	const [currentFiles, setCurrentFiles] = useState<FileModel[]>([]);
	//Todo: New files sert vraiment a quelque chose ?
	const [newFiles, setNewFiles] = useState<File[]>([]);
	//Todo: Re implémenter la limite d'upload de fichiers
	const [fileLimit, setFileLimit] = useState<boolean>(true);

	function filesToModels(files: File[]) : FileModel[]
	{
		let newFiles : FileModel[] = [];
		files.forEach(file => {
			newFiles.push(fileToFileModel(file));
		});
		return newFiles;
	}

	function fileToFileModel(file: File) : FileModel
	{
		return {
			articleId: 0,
			id: 0,
			name: file.name,
			path: "",
			toDelete: false,
			rawFile: file,
		};
	}

	function handleFileChange(event: React.ChangeEvent<HTMLInputElement>)
    {
        const chosenFiles = Array.prototype.slice.call(event.target.files);
        handleUploadFile(chosenFiles);
    }

    function handleUploadFile(files: any[])
    {
		// Est ce que newFiles sert vraiment a quelque chose ?
        const uploaded : File[] = [...newFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_FILE_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_FILE_COUNT) {
                    toast.error("Vous ne pouvez ajouter que 3 fichiers maximum");
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded)
		{
			let test = filesToModels(uploaded);
			test.forEach(file => {
				if(!currentFiles.find((currentFile => currentFile.name === file.name)))
				{
					console.log("pute");
					props.onFileChange(file);
				}
			});
		}

    }

	useEffect(() => {
		setCurrentFiles(props.initialFiles);
	}, [, props.initialFiles]);

	return (
	<div className='flex flex-col gap-2'>
		<h2 className='text-md font-semibold text-[#0b1e5b]'>Pièces jointes</h2>
		<div>
			<label className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none flex items-center justify-center">
				<span className="flex items-center space-x-2 flex flex-col gap-2">
					<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>
					<span className="font-medium text-gray-600 flex flex-col items-center gap-1">Déposez les fichiers ou<span className="text-blue-600 underline">cliquez.</span></span>
				</span>
				<input type="file" name="files" className="hidden" multiple onChange={handleFileChange} />
			</label>
		</div>
		<div className='flex flex-col gap-2'>
			{
				currentFiles.map((file, index) => {
					return <Attachment attachmentName={file.name} file={file} key={index} 
					onDelete={(file) => {
						props.onFileChange(file);
					}} />
				})
			}
		</div>
	</div>
  );
}

export default FileSelector