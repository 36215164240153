import { toast } from "react-hot-toast";
import privateApi from "../../../../api/axiosapi";
import { Article } from "../../../../types/Article.type";
import { Category } from "./DashboardOffers";

const configToSendFile = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
};

function getArticleFormData(article: Article) : FormData {
    let form = new FormData();

    form.append('id', article.id.toString());
    form.append('title', article.title);
    form.append('body', article.body);
    form.append('backgroundImage', article.backgroundImage);    

    article.categories.forEach(category => {
        form.append('categoryIds[]', category.id.toString());
    });

    return form;
}

async function createArticle(article: Article) : Promise<boolean>
{
    let articleFormData = getArticleFormData(article);
    articleFormData.delete('id');

    privateApi.post<Article>('/article/create', articleFormData).then((response) => {
        if (response.status === 400)
        {
            toast.error("Impossible de créer l'article");
            return false;
        }
        else
        {
            toast.success("Article créer !");

            article.files.forEach(async (file) => {
                if (file.toDelete) return;
                if (file.rawFile === undefined) return;

                let fileFormData = new FormData();
                fileFormData.append('file', file.rawFile);

                await privateApi.post(`/file/article/${response.data.id}/upload`, fileFormData, configToSendFile)
                    .then((response) => {
                        if (response.status === 400)
                            toast.error("Impossible d'insérer la pièce jointe");
                        else
                            toast.success("Pièce jointe ajoutée");
                    }).catch((error) => {
                        console.log(error);
                    });
            });
        }
    }).catch((error) => {
        console.log(error);
        toast.error("Une erreur s'est produite lors de la création de l'article");
        return false;
    });
    return true;
}

async function updateArticle(article: Article) : Promise<boolean> 
{
    let articleFormData = getArticleFormData(article);

    privateApi.post<Article>('/article/update', articleFormData)
        .then((response) => {
            if (response.status === 400)
            {
                toast.error("Impossible de mettre à jour l'article");
                return false;
            }
            else
            {
                toast.success("Article mis à jour !");
                article.files.forEach(async (file) => {
                    if (file.id > 0 && file.toDelete)
                    {
                        // Faire un call api pour Supprimer le fichier
                        await privateApi.get(`/file/delete/${file.id}`)
                            .then((response) => {
                                if (response.status === 400)
                                    toast.error("Impossible de supprimer un fichier");
                                else
                                    toast.success("Fichier supprimé !");
                            })
                            .catch((error) => {
                                console.log("Erreur lors de la suppresion du fichier");
                                console.log(error);
                            });
                    }
                    if (file.id === 0 && !file.toDelete)
                    {
                        // Faire un call api pour insérer le fichier
                        if (file.rawFile === undefined) return;
                        
                        let fileFormData = new FormData();
                        fileFormData.append('file', file.rawFile);

                        await privateApi.post(`/file/article/${article.id}/upload`, fileFormData, configToSendFile)
                            .then((response) => {
                                if (response.status === 400)
                                    toast.error("Impossible de créer la pièce jointe");
                                else
                                    toast.success("Pièce jointe ajoutée !");
                            }).catch((error) => {
                                console.log("Erreur lors de l'insertion du fichier");
                                console.log(error);
                            });
                    }
                });
            }
        });
    return true;
}

export async function submitArticle(article: Article, selectedCategory: Category[]) : Promise<boolean>
{
    article = {...article, categories: selectedCategory};

    if (article.id === 0)
        return createArticle(article);
    return updateArticle(article);
}