import React from 'react'
import { Link } from 'react-router-dom';

interface IFooterLinks {
	sectionTitle: string;
	sectionLinks: {
		name: string;
		href: string;
	}[];
};

const FOOTER_LINKS: IFooterLinks[] = [
	{
		sectionTitle: "Nos contacts",
		sectionLinks: [
			{
				name: "intercos37@gmail.com",
				href: "#"
			},
			{
				name: "contact@everate.fr",
				href: "#"
			},
		]
	},
	{
		sectionTitle: "Liens rapides",
		sectionLinks: [
			{
				name: "A props de nous",
				href: "#"
			},
			{
				name: "FAQ",
				href: "#"
			},
			{
				name: "Contact",
				href: "#"
			},
		]
	},
	{
		sectionTitle: "Divers",
		sectionLinks: [
			{
				name: "Termes et conditions",
				href: "#"
			},
			{
				name: "Police de confidentialité",
				href: "#"
			},
		]
	},
	{
		sectionTitle: "Everate",
		sectionLinks: [
			{
				name: "Site créé par Everate Corp.",
				href: "https://everate.fr"
			},
			{
				name: "Prendre contact avec eux",
				href: "#"
			}
		]
	}
];

function Footer() {
  return (
    <div className='bg-[#141d2a] text-white p-10 flex flex-col gap-10 text-[#b5ccd7] md:flex-row md:justify-between py-32 mt-24 relative'>

		<div className='bg-[#f5feff] border-[#e9ebec] border-[1px] shadow-md rounded w-3/4 absolute -top-24 md:-top-18 p-5 flex flex-col gap-5 left-0 right-0 m-auto md:p-12'>
			<h3 className='text-[#041c2f] text-2xl font-semibold md:text-[32px]'>Contactez nous!</h3>
			<div className='flex flex-col'>
				<p className='text-[#9b9e9f]'>Revenez vite nous voir et n'hésitez pas à rentrer en contact avec notre équipe pour plus de détails et précisions.</p>
				<button></button>
			</div>
		</div>

		<div className='flex flex-col md:flex-row flex-wrap gap-10 justify-center w-full'>

			<div className='flex flex-col'>
				<span className='flex gap-2 items-center text-white text-2xl font-medium'><img src="/logo-intercos37.png" height={10} width={50} alt="" /> Intercos37</span>
				<span className='text-[#6a6f79] font-light'>Copyright {new Date().getFullYear()} @ Intercos37</span>
			</div>

			<div className='flex flex-col md:flex-row gap-10 flex-wrap'>
				{
					FOOTER_LINKS.map((element, index) => {
						return (
							<div key={index} className='flex flex-col gap-5'>
								<h3 className='text-xl font-medium'>{element.sectionTitle}</h3>
								<div className='flex flex-col gap-2'>
								{
									element.sectionLinks.map((link, id) => {
										return (
											<Link to={link.href} key={id}>
												<span className='text-[#6a6f79]'>{link.name}</span>
											</Link>
										);
									})
								}
								</div>
							</div>
						);
					})
				}
			</div>

		</div>

       


		

    </div>
  )
}

export default Footer