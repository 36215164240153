import React from 'react'

interface CommentProps {
    image?: string;
    pseudo: string;
    text: string;
}

function Comment(props: CommentProps) {
    return (
        <div className='flex gap-3 items-center rounded-lg hover:bg-[#f8f8f8] p-3 transition-all cursor-pointer'>
            <div className='w-10 h-10 flex-shrink-0 overflow-hidden rounded-lg'>
                <img src={props.image} className='rounded-lg' alt="" />
            </div>
            <div className='flex flex-col'>
                <div className='flex gap-1'>
                    <span className='text-[#f72886] text-sm font-bold'>{props.pseudo}</span>
                    <span className='text-sm font-light text-[#9097ae]'>a commenté</span>
                </div>
                <div>
                    <p className='text-sm'>{props.text}</p>
                </div>
            </div>
        </div>
    );
}

const data = [
    {
        image: 'https://source.boringavatars.com/beam/',
        pseudo: 'Lil Paprik',
        text: 'Lorem Ipsum'
    },
    {
        image: 'https://source.boringavatars.com/bauhaus/',
        pseudo: 'Malik Manibamyu',
        text: 'Lorem Ipsum paprika amet si lorem mat'
    },
    {
        image: 'https://source.boringavatars.com/pixel/',
        pseudo: 'LilianuxQ_Q',
        text: 'Lorem Ipsum dolor sit imet'
    },
]

function RecentComment() {
  return (
    <div className='flex flex-col bg-white w-[300px] rounded-xl shadow-sm'>
        <div className='flex justify-between text-[#003049] px-5 py-5'>
            <span className='text-sm font-semibold'>Commentaires Récents</span>
            <span><i className="fa-solid fa-ellipsis"></i></span>
        </div>
        <div className='flex flex-col gap-1 p-2'>
            {data.map((element, index) => {
                return (<Comment pseudo={element.pseudo} text={element.text} image={element.image} key={index} />);
            })}
        </div>
    </div>
  )
}

export default RecentComment