import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import slugify from 'react-slugify'
import { publicApi } from '../../api/axiosapi'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import { Article } from '../../types/Article.type'
import AboutCards from './components/AboutCards'
import BubbleImage from './components/BubbleImage'
import OffersCard from './components/OffersCard'
import QuestionCard from './components/QuestionCard'

function Home() {

	const [articles, setArticles] = useState<Article[]>([]);

	useEffect(() => {
		publicApi.get<Article[]>('/article/getlast').then((response) => {
			setArticles(response.data);
		}).catch((error) => {
			console.log("Erreur lors de la récupération des derniers articles.");
			console.log(error);
			toast.error("Liste des derniers articles momentanément indisponible.");
		});
	}, []);

  return (
	<div className='w-full h-screen flex flex-col gap-14'>
		
		<div className='w-full bg-[#0b2336] bg-[url("/Untitled.webp")] bg-cover text-white relative'>
			<Navbar buttonColor='#213c4c' />
			<div className='flex flex-col'>
				<div className='flex flex-col md:flex-row md:justify-center md:gap-10'>
					<div className='flex h-auto flex-col text-4xl p-8 gap-1 md:gap-5'>
						<span className='font-bold md:text-[50px]'>L'intercos37</span>
						<span className='text-[#fbf153] font-bold md:text-[50px]'>Offres COS et CSE</span>
						<span>Pour Vous</span>

						<div className='mt-10 flex flex flex-col items-start gap-2'>
							<a href="#questions" className='text-sm bg-[#fbf153] rounded-full text-[#051d2e] font-bold p-2 px-5'>Devenir Partenaire</a>
						</div>
					</div>
					<div className='flex flex-col flex-col items-center'>
						<div className='flex items-center justify-center gap-5'>
							<BubbleImage img='/metiers-massage.jpeg' />
							<BubbleImage img='/Parachute.jpeg' mt={100} size={100}/>
						</div>
						<div>
							<BubbleImage img='/Randonnée.jpg' size={190}/>
						</div>
					</div>	
				</div>
				<svg xmlns="http://www.w3.org/2000/svg" className='-mb-1' viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,128L34.3,160C68.6,192,137,256,206,272C274.3,288,343,256,411,229.3C480,203,549,181,617,176C685.7,171,754,181,823,208C891.4,235,960,277,1029,261.3C1097.1,245,1166,171,1234,122.7C1302.9,75,1371,53,1406,42.7L1440,32L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
			</div>
		</div>

		<div className='flex flex-col gap-10 items-center justify-center relative'>
			<div className='flex flex-col items-center justify-center gap-3'>
				<h2 id='knowmore' className='text-2xl md:text-4xl font-semibold text-[#062036]'>A Propos De Nous</h2>
				<p className='text-[#8f989e] p-2 text-center font-light text-xl'>L'intercos37 c’est la réunion de 30 CE/Amicales locales.</p>
			</div>
			<div className='flex flex-col gap-10 overflow-x-hidden md:flex-row md:items-center'>
				<AboutCards odd={true} color='#ffa9ab' secondColor='#e83236' desc='' title="Plus de 9000 adhérents" faicon='fa-solid fa-people-group' />
				<AboutCards odd={false} color='#99b6fe' secondColor='#4d7cec' desc='' title='Une carte valable dans tout le département' faicon='fa-solid fa-map-location-dot' />
				<AboutCards odd={true} color='#ffe0b3' secondColor='#feb750' desc='Sport, beauté, automobile... Vous trouverez de tout !' title='Des offres intéressantes' faicon='fa-solid fa-money-bill-1-wave' />
				{/** <AboutCards odd={false} color='#fec7e4' secondColor='#ff76c0' desc='ezezezeze' title='24/7 Support' faicon='fa-solid fa-headset' /> */ }
			</div>
		</div>

		<section data-aos='fade-up' className='w-full flex flex-col md:items-center'>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#063449" fill-opacity="1" d="M0,64L34.3,58.7C68.6,53,137,43,206,48C274.3,53,343,75,411,69.3C480,64,549,32,617,37.3C685.7,43,754,85,823,112C891.4,139,960,149,1029,160C1097.1,171,1166,181,1234,160C1302.9,139,1371,85,1406,58.7L1440,32L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
			<div className='-mt-1 bg-[#063449] flex flex-col p-10 gap-4 text-white font-bold text-xl w-full items-start md:items-center md:gap-10'>
				<span className='text-[40px] md:text-[50px]'>Une question ?</span>
				<span className='md:text-[32px] text-2xl font-semibold'>Notre équipe est toujours prête a vous aider</span>
				<p className='text-sm font-medium text-[#899fa8] md:text-[15px]'>En cas de non réponses n'hésitez pas à nous laisser un message avec votre nom et prénom</p>
				<div className='flex mt-10 items-center gap-4 text-sm flex-col justify-center'>
					<a href="#questions"><span className='p-2 px-5 text-[#10160d] bg-[#fcf151] rounded-full text-lg md:text-xl md:py-3'>Contactez nous !</span></a>
				</div>
			</div>
			<svg className='-mt-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#063449" fill-opacity="1" d="M0,64L34.3,80C68.6,96,137,128,206,160C274.3,192,343,224,411,213.3C480,203,549,149,617,144C685.7,139,754,181,823,186.7C891.4,192,960,160,1029,165.3C1097.1,171,1166,213,1234,213.3C1302.9,213,1371,171,1406,149.3L1440,128L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path></svg>
		</section>

		<section className='flex flex-col items-center justify-center gap-5 md:gap-16'>
			<h3 className='text-[28px] font-bold text-[#062036] md:text-[40px] md:-mt-16 md:font-semibold'>Nos dernières offres</h3>
			<div className='flex flex-col gap-10 md:flex-row'>
				{
					!(articles.length < 1) ?
						articles.map((element, index) => {
							console.log(element);
							return <OffersCard key={index} color={index < 1 ? '#eeffee' : '#ecf8ff'} title={element.title} img={element.backgroundImage ? element.backgroundImage : `https://picsum.photos/200/10${index}`} desc="Aucune description" id={element.id} slug={slugify(element.title)} />		
						}) : <span className='text-[#939ca2] font-semibold text-sm'>Aucuns articles à afficher...</span>
				}
			</div>
		</section>

		<section className='w-full bg-[#ebf9ff] relative flex flex-col items-center p-5'>
			<a href="https://www.acef-valdefrance.com/">
				<div className='w-36 h-36 bg-white rounded-full shadow-md flex flex-col items-center justify-center relative'>
					<img className='p-2 w-3/4 h-3/4' src="./acef-logo.png" alt="" />
				</div>
			</a>
		</section>

		<section className='flex flex-col items-center w-full gap-10'>
			<h3 id='questions' className='text-[#041c2f] font-bold text-2xl md:text-2xl'>Questions fréquentes</h3>
			<div className='flex flex-col w-3/4'>
				<QuestionCard 
					title='Puis-je devenir partenaire ?' 
					content={`Vous souhaitez devenir partenaire de l'Intercos37 ?
					Pour cela, téléchargez le formulaire ci-joint.

					Remplissez-le et envoyez le à l' adresse suivante : intercos37@gmail.com.
					Nous étudierons votre proposition et vous répondrons dans les meilleurs délais.`}
					files={[{
						label: "Formulaire Partenaire",
						location: "./downloadable/partenariat_intercos.pdf"
					}]}
				/>
				<QuestionCard 
					title='Qui est votre sponsor ?' 
					content={`Depuis toujours, la solidarité est au cœur des valeurs de l’ACEF Val de France.

					L’ACEF Val de France se mobilise, accompagne et soutient des associations ou des projets portés par les personnels des services publics ou de santé.
					
					Aujourd’hui l’ACEF Val de France est ravie de participer financièrement à l’achat de matériel informatique au côté de votre INTERCOS 37.
					
					Devenir adhérent ACEF, c’est être acteur en contribuant à la réussite des actions menées par nos collègues dans de multiples domaines : humanitaire, handicap, sport, culture, formation, financement d’équipements…
					
					Pourquoi pas vous ?`}
					files={[{
						label: "En savoir plus",
						location: "https://www.acef-valdefrance.com/"
					}]}
				/>
				<QuestionCard 
					title='Comment profiter des offres ?' 
					content='Sur chaque offres vous trouverez une pièce jointe à télécharger, faites en usage pour profiter de vos avantages !' 
				/>
			</div>
		</section>

		<Footer />

	</div>
  )
}

export default Home