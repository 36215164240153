import React, { useContext } from 'react'
import { UserContext } from '../../../contexts/UserContext';
import Button from './Button';


function Navbar() {
	const user = useContext(UserContext);

	return (
		<div className='w-full flex justify-end items-center gap-5'>
			<div className='flex gap-3'>
				<Button redirect='#' notif={0} icon='fa-bell' color='#f72485'/>
				<Button redirect='#' notif={0} icon='fa-comment' />
			</div>
			<div className='flex gap-5 items-center'>
				<span className='font-semibold text-[#0b1e5b]'>Bonjour, {user.displayName}</span>
				<div className='w-10 h-10 border-2 border-white rounded-full bg-[url("/Parachute.jpeg")] bg-cover flex-shrink-0'></div>
			</div>
		</div>
	)
}

export default Navbar