import React from 'react'

interface TagProperty
{
    label: string;
}

function Tag(props: TagProperty) {
  return (
    <div className='border-2 border-white font-bold px-3 rounded-full p-1 text-white'>
        {props.label}
    </div>
  )
}

export default Tag