import React from 'react'
import { Category } from '../pages/offer/DashboardOffers';

interface TagProps {
    category: Category;
    onClick: (category: Category) => void;
}

function Tag(props: TagProps) {
  return (
    <div className='border-solid border-[#1481e8] text-[#1481e8] rounded-lg border-2 p-1 px-5 flex items-center relative'>
        {props.category.name}
        <span className='absolute w-5 h-5 flex items-center justify-center rounded-full -top-2 -right-2 bg-[#1481e8] border-solid border-2 border-[#1481e8] text-white cursor-pointer' onClick={() => {
          props.onClick(props.category);
        }}><i className="fa-solid fa-xmark"></i></span>
    </div>
  )
}

export default Tag