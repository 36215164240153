import React from 'react'

interface BubbleImageProps
{
	img: string;
	border?: number;
	mt?: number;
	ml?: number;
	mr?: number;
	mb?: number;
	size?: number;
}

function BubbleImage(props: BubbleImageProps) {
	return (
		<div className={`
			bg-white bg-cover bg-[url("${props.img}")]
			${props.size ? `w-[${props.size}px] h-[${props.size}px]` : 'w-36 h-36'} 
			rounded-full 
			relative 
			flex justify-center items-center 
			border-[${props.border ? props.border : '3'}px]
			mt-[${props.mt ? props.mt : '0'}px]
			ml-[${props.ml ? props.ml : '0'}px]
			mr-[${props.mr ? props.mr : '0'}px]
			mb-[${props.mb ? props.mb : '0'}px]
			`}>
		</div>
	)
}

export default BubbleImage