import React from 'react'
import { Link } from 'react-router-dom';

interface OffersCardProps
{
	title: string;
	desc: string;
	img: string;
	color: string;
	id: number;
	slug: string;
}

function OffersCard(props: OffersCardProps) {
  return (
	<Link to={`/offres/${props.id}/${props.slug}`}>
		<div className={`bg-[${props.color}] w-72 h-auto rounded-xl hover:-rotate-[3deg] transition hover:cursor-pointer`}>
			<div className='p-5 flex flex-col gap-2 text-[#071f32]'>
				<img className='rounded-lg w-full h-36' src={props.img} alt="" />
				<span className='text-md font-bold'>{props.title.substring(0,30)}{props.title.length > 30 ? '...' : '.'}</span>
				<span className='w-full flex justify-end mt-5'>
					<div className='w-8 h-8 rounded-full bg-white text-[#071f32] flex items-center justify-center shadow-md'><i className="fa-solid fa-chevron-right"></i></div>
				</span>
			</div>
		</div>
	</Link>
  )
}

export default OffersCard