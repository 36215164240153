import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api/axiosapi';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import { Article } from '../../types/Article.type';
import DownloadButton from './components/DownloadButton';
import Tag from './components/Tag';
import { stateToHTML } from 'draft-js-export-html';
import { format } from 'date-fns';
import { convertFromRaw } from 'draft-js';
import { fr } from 'date-fns/locale';
import { FileModel } from '../../types/File.type';

function OffresPage() {

	const [article, setArticle] = useState<Article>();
	const [articleDate, setArticleDate] = useState<string>("2023-04-01T10:19:48.705Z");
	const [articleHtml, setArticleHtml] = useState<string>("");
	const [files, setFiles] = useState<FileModel[]>([]);

	const navigate = useNavigate();

	const { id } = useParams<{id: string}>();

	useEffect(() => {
		api.get<Article>(`/article/get/${id}`).then((response) => {
			setArticle(response.data);
			setFiles(response.data.files)
			const contentState = convertFromRaw(JSON.parse(response.data.body));
			const html = stateToHTML(contentState);
			setArticleHtml(html);
			setArticleDate(response.data.createdAt.toString());
		}).catch(() => {
			toast.error("Désolé cet article n'existe pas / plus.");
			navigate('/');
		});
	}, [id, navigate]);

	return (
		<div className='w-full h-screen'>
			<div className='w-full flex-col h-auto bg-[#063449] text-white justify-center items-center relative'>
				<Navbar />
				<div className='flex items-center justify-center flex-col gap-10 text-[#121826] py-10'>
					<div className='flex flex-col gap-2 items-center'>
						{/** Put the title of the article here */}
						<h3 className='text-center font-bold text-4xl p-2 px-5 text-white'>{article?.title}</h3>
					</div>
					<div className='flex flex-wrap gap-2'>
						{article?.categories.map((element, index) => {
							return <Tag key={index} label={element.name} />
						})}
					</div>
					<div>
						<p className='text-sm text-white'>Posté le <span className='font-semibold'>{format(new Date(articleDate), 'd MMMM yyyy', {locale: fr})}</span> par <span className='font-semibold'>{article?.author.displayName}</span></p>
					</div>
				</div>
			</div>
			<div className={'flex flex-col items-center justify-start p-10 gap-10 min-h-screen'}>
				<div className='flex flex-col gap-2 w-full md:w-3/4'>
					<div className='article p-5 flex flex-col gap-2' dangerouslySetInnerHTML={{__html: articleHtml}}></div>
				</div>
				<div className='w-full flex flex-col gap-5'>
					{files.length > 0 ? <span className='font-semibold text-lg'>Pièces jointes à l'article</span> : <></>}
					<div className='flex flex-wrap gap-5'>
						{
							files.map((element, index) => {
								return (<DownloadButton key={index} label={element.name} path={element.path} />)
							})
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default OffresPage