import { spawn } from 'child_process'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { publicApi } from '../../api/axiosapi'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import { Article } from '../../types/Article.type'
import CategoryMenu from './components/CategoryMenu'
import OfferCard from './components/OfferCard'

function OffresList() {

	const [offers, setOffers] = useState<Article[]>([]);
	const { id } = useParams();

	useEffect(() => {
		if (id)
			publicApi.get<Article[]>(`/article/getall/${id}`)
				.then((response) => {
					setOffers(response.data);
				});
		else
			publicApi.get<Article[]>(`/article/getall`)
				.then((response) => {
					setOffers(response.data);
				});
	}, [id]);

  return (
	<>
		<div className='bg-[#f2fdfe] flex flex-col'>
			<Navbar />
	  	</div>

		<div className='bg-[#f2fdfe] flex flex-col items-center p-5'>
			<h2 className='text-[30px] font-semibold text-center'>Nos Offres Chez Intercos37.</h2>
			<p className='text-[#b2b8b8] font-semibold text-sm p-5 text-center'>Profitez de toutes les offres <br />dont nous disposons grâce a nos partenaires !</p>
		</div>

		<section className='p-10 flex flex-col gap-12 min-h-screen'>
			<CategoryMenu />
			<div className='flex gap-16 flex-wrap md:justify-around justify-center'>
				{offers.map((element, index) => {
					return <OfferCard key={index} article={element} imageUrl={element.backgroundImage ? element.backgroundImage : `https://picsum.photos/300/30${index}`} />;
				})}
				<span className={`text-2xl font-medium ${offers.length < 1 ? 'flex' : 'hidden'}`}>Aucun articles trouvés...</span>
			</div>
		</section>

		<div>
			<Footer />
		</div>
	</>
  )
}

export default OffresList