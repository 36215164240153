import React, { useEffect } from 'react'
import { FileModel } from '../../../types/File.type'

interface AttachmentProps {
    attachmentName: string,
    file: FileModel,
    onDelete: (file: FileModel) => void;
}

function Attachment(props: AttachmentProps) {

    useEffect(() => {

    }, [props.file])

  return (
    <span className={`border-solid border-[#1481e8] text-[#1481e8] rounded-lg border-2 p-1 px-5 flex items-center relative ${props.file.toDelete ? 'hidden' : 'flex'}`}>
        {props.attachmentName.length > 20 ? props.attachmentName.substring(0, 30) + '...' : props.attachmentName}
        <span className='absolute w-5 h-5 flex items-center justify-center rounded-full -top-2 -right-2 bg-[#1481e8] border-solid border-2 border-[#1481e8] text-white cursor-pointer'
            onClick={() => { props.onDelete(props.file); }}>
            <i className="fa-solid fa-xmark"></i>
        </span>
    </span>
  )
}

export default Attachment