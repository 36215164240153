import { PieChart as PieRechart, Pie, Cell } from 'recharts';

interface PieChartValueProps {
    label: string;
    value: string;
    color?: string;
    icon ?: string;
}

interface PieChartProps {
    tabname: string;
    label: string;
    pieMainColor?: string;
    pieSecondaryColor?: string;
    value1: PieChartValueProps;
    value2: PieChartValueProps;
}

function PieChartValue(props: PieChartValueProps) {
    return (
        <div className='flex flex-col gap-1 items-center'>
            <span className='text-[#868fa7] text-sm font-semibold'>{props.label}</span>
            <div className={`flex gap-1 font-bold text-sm items-center ${props.color ? `text-[${props.color}]` : `text-[#24b07d]`}`}>
                <span>{props.value}</span>
                <span><i className={`fa-solid ${props.icon}`}></i></span>
            </div>
        </div>
    )
}

function PieChart(props: PieChartProps) {

    //TODO: Need a refactor

    const data = [
        { name: 'Group A', value: 25 },
        { name: 'Group B', value: 75 },
      ];

    const COLORS = ['#edf1fa', '#0088FE'];

  return (
    <div data-aos="fade-right" className='flex flex-col gap-2 p-5 bg-white w-[300px] rounded-xl shadow-sm'>
        <div className='flex justify-between text-[#003049]'>
            <span className='text-sm font-semibold'>{props.tabname}</span>
            <span><i className="fa-solid fa-ellipsis"></i></span>
        </div>
        <div className='flex items-center justify-center relative'>
            <span className='absolute text-[#003049] font-bold text-2xl'>{props.label}</span>
            <PieRechart width={200} height={200}>
                <Pie
                    data={data}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={-10}
                    cornerRadius={40}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                </Pie>
            </PieRechart>
        </div>
        <div className='flex justify-between px-5'>
            <PieChartValue label={props.value1.label} value={props.value1.value} color={props.value1.color} icon={props.value1.icon} />
            <PieChartValue label={props.value2.label} value={props.value2.value} color={props.value2.color} icon={props.value2.icon}/>
        </div>
    </div>
  )
}

export default PieChart