import React from 'react'

interface SlideProps
{
    image: string;
    title: string;
    description?: string;
    comment?: string;
    author?: string;
}

function Slide(props: SlideProps) {
  return (
    <div className={`bg-[url('${props.image}')] bg-cover w-full h-full flex flex-col items-center justify-around`}>
        <div className='w-3/4 p-6 flex flex-col gap-5'>
            <span className='text-white text-[40px] font-semibold'>{props.title}</span>
            <p className='text-[#c9d4ff]'>{props.description}</p>
        </div>
        <div className={`${props.comment && props.author ? 'flex' : 'hidden' } w-3/4 p-6 rounded-xl bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 text-white flex-col gap-1`}>
            <span>{props.comment}</span>
            <span className='font-semibold flex justify-end'>{props.author}</span>
        </div>
    </div>
  )
}

export default Slide