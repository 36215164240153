import { useState } from 'react'
import { Article } from '../types/Article.type';

export function useInitialArticleState(): Article
{
    const [initialState] = useState<Article>({
        id: 0,
        authorId: 0,
        title: '',
        body: '',
        backgroundImage: '',
        createdAt: '',
        modifiedAt: '',
        categories: [],
        author: {
            displayName: ''
        },
        files: [],
    })

    return initialState;
}