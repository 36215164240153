import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { publicApi } from '../../../api/axiosapi';
import { Category } from '../../dashboard/pages/offer/DashboardOffers'

function CategoryMenu() {
	
	const [categories, setCategories] = useState<Category[]>([]);
	const { id } = useParams();

	useEffect(() => {
		publicApi.get<Category[]>('/categories/getallactive')
			.then((response) => {
				setCategories(response.data);
			});
	}, []);

	return (
		<div className='border-b-2 py-3 border-gray-100]'>
			<ul className='flex gap-5 text-sm font-semibold text-[#c7c7c7] transition-all flex-wrap'>
				<Link to={'/offres'}><li className={`p-2 px-5 rounded uppercase font-medium border-solid border-2 border-[#42e97c] text-md hover:bg-[#42e97c] hover:text-white ${id ? 'text-[#656565]' : 'bg-[#42e97c] text-white'}`}>Tout</li></Link>
				{categories.map((element, index) => {
					return <Link to={`/offres/${element.id}`}><li className={`p-2 px-5 rounded border-solid border-2 border-[#42e97c] uppercase font-medium text-md transition-all hover:bg-[#42e97c] hover:text-white ${element.id.toString() === id ? 'bg-[#42e97c] text-white' : 'text-[#656565]'}`} key={index}>{element.name}</li></Link>;
				})}
			</ul>
		</div>
	)
}

export default CategoryMenu