import React from 'react'
import { Route, Routes } from 'react-router-dom';
import BackendAvailability from '../components/check/BackendAvailability';
import Dashboard from '../pages/dashboard/Dashboard';
import DashboardHome from '../pages/dashboard/pages/DashboardHome';
import DashboardOffers from '../pages/dashboard/pages/offer/DashboardOffers';
import Home from '../pages/home/Home';
import Login from '../pages/login/Login';
import Logout from '../pages/login/Logout';
import Maintenance from '../pages/maintenance/Maintenance';
import OffresList from '../pages/offres/OffresList';
import OffresPage from '../pages/offres/Offrespage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

interface RouteProps
{
	path: string;
	element: JSX.Element;
}

const routes : RouteProps[] = [
	{
		path: '/',
		element:
			<PublicRoute>
				<Home />
			</PublicRoute>
	},
	{
		path: '/login',
		element:
			<PublicRoute>
				<Login />
			</PublicRoute>
	},
	{
		path: '/forgotten',
		element:
			<PublicRoute>
				<Maintenance title='Mince alors 😮 !' message='Il semble que cette page soit désactivée.' />
			</PublicRoute>
	},
	{
		path: '/offres',
		element:
			<PublicRoute>
				<OffresList />
			</PublicRoute>
	},{
		path: '/offres/:id',
		element:
			<PublicRoute>
				<OffresList />
			</PublicRoute>
	},
	{
		path: '/offres/:id/:slug',
		element:
			<PublicRoute>
				<OffresPage />
			</PublicRoute>
	},
	{
		path: '/dashboard',
		element: 
			<BackendAvailability>
				<PrivateRoute>
					<Dashboard sidebarActiveLabel='Dashboard'>
						<DashboardHome />
					</Dashboard>
				</PrivateRoute>
			</BackendAvailability>
	},
	{
		path: '/dashboard/offers',
		element: 
			<BackendAvailability>
				<PrivateRoute>
					<Dashboard sidebarActiveLabel='Offres'>
						<DashboardOffers />
					</Dashboard>
				</PrivateRoute>
			</BackendAvailability>
	},
	{
		path: '/dashboard/offers/update/:id',
		element:
			<BackendAvailability>
				<PrivateRoute>
					<Dashboard sidebarActiveLabel='Offres'>
						<DashboardOffers />
					</Dashboard>
				</PrivateRoute>
			</BackendAvailability>
	},
	{
		path: '/logout',
		element:
			<PublicRoute>
				<Logout />
			</PublicRoute>
	},
	{
		path: '/partenaires',
		element:
			<PublicRoute>
				<Maintenance title='Page indisponible...' message="La liste des partenaires n'est actuellement pas disponbile." />
			</PublicRoute>
	}
]

function Router() {
	return (
		<>
			<Routes>
				{
					routes.map((elem, index) => <Route key={index} path={elem.path} element={elem.element} />)
				}
			</Routes>
		</>
	)
}

export default Router