import React, { useEffect, useState } from 'react'

interface QuestionCardProps
{
    title: string;
    content: string;
    files?: LinkedFile[];
}

interface LinkedFile
{
    label: string;
    location: string;
}

function QuestionCard(props: QuestionCardProps) {

    const [open, setOpen] = useState(false);

  return (
    <div className='flex flex-col border-b-[1px] border-[#d1d5d7] py-2 transition-all'>
        <div className={`flex justify-between items-center`}>
            <span className='text-[#092136] font-semibold text-lg'>{props.title}</span>
            <div onClick={() => setOpen(!open)} className={`border-2 px-3 py-3 ${open ? 'text-[#b83c32] border-[#b83c32]' : 'text-[#031b30] border-[#031b30] '}  rounded flex items-center justify-center hover:cursor-pointer`}><i className={`fa-solid ${open ? 'fa-minus' : 'fa-plus'}`}></i></div>
        </div>
        <div className={`${open ? 'h-auto' : 'h-0'} overflow-hidden py-1 flex flex-col gap-1`}>
            {/** TODO: Mettre dans les propriétés de quoi faire une boucle sur les fichiers en pièce jointe. */}
            {props.content.split("\n").map(str => <p className='text-[#626e78] font-md'>{str}</p>)}
            <div className='mt-2'>
                {props.files?.map((item) => {
                    return (
                        <a className='px-3 border-2 p-1 border-[#f431a5] rounded-full text-[#f431a5] hover:bg-[#f431a5] hover:text-white transition-all font-semibold' href={item.location} download='Formulaire Partenariat Intercos'>{item.label}</a>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default QuestionCard