import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/various/ScrollToTop';
import { Toaster } from 'react-hot-toast';
import Router from './routes/Router';

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Router />
			<Toaster />
		</BrowserRouter>
	);
}

export default App;
