import { convertFromRaw, EditorState } from 'draft-js';
import React from 'react'
import { Link } from 'react-router-dom'
import slugify from 'react-slugify';
import { Article } from '../../../types/Article.type'

interface OfferCardProps {
	article: Article;
	imageUrl: string;
}

function OfferCard(props: OfferCardProps) {

	const article = props.article;
	const description = EditorState.createWithContent(convertFromRaw(JSON.parse(article.body))).getCurrentContent().getPlainText().substring(0, 40);
	const hasBeenAWeek = Math.floor((new Date().getTime() - new Date(article.createdAt).getTime()) / (1000 * 60 * 60 * 24)) >= 7;

	return (
		<div className='flex flex-col gap-2 w-64'>
				<div className={`w-64 h-40 bg-[url("${props.imageUrl}")] bg-gray-100 bg-cover rounded-md relative`}>
					<span className={`absolute top-6 bg-white rounded -left-3 p-1 px-4 text-xs font-semibold shadow-md uppercase text-[#656565] ${hasBeenAWeek ? 'hidden' : 'flex'}`}>Nouveau</span>
				</div>
				<div className='bg-[#f2feff] p-2 uppercase text-[#6e7475] text-xs font-semibold tracking-wide'>{article.categories.at(0)?.name}</div>
				<span className='text-black text-lg font-semibold'>
					{article.title.length > 16 ? `${article.title.substring(0,16)}...` : article.title }
				</span>
				<p className='text-[#afafaf] text-sm font-medium'>
					{description.length > 33 ? `${description.substring(0, 30)}...` : description}
				</p>
				<div className='mt-5'>
					<Link to={`/offres/${article.id}/${slugify(article.title)}`}><span className='p-3 bg-[#01e87c] text-white font-semibold text-md rounded-md px-6'>Consulter</span></Link>
				</div>
		</div>
	)
}

export default OfferCard