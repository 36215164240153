import React from 'react'
import { Link } from 'react-router-dom';

interface ButtonProps {
    icon: string;
    notif: number;
    redirect: string;
    color?: string;
}

function Button(props: ButtonProps) {
  return (
    <Link to={'#'}>
        <div className='relative w-10 h-10 bg-white rounded-lg flex items-center justify-center text-[#1181e8] cursor-pointer transition-all hover:-rotate-[12deg]'>
            <i className={`fa-solid ${props.icon}`}></i>
            <span className={`w-5 h-5 absolute -top-2 -right-2 rounded-full text-white text-[11px] font-semibold flex items-center justify-center animate-pulse ${props.color ? `bg-[${props.color}]` : 'bg-[#1181e8]'}`}>{props.notif}</span>
        </div>
    </Link>
  )
}

export default Button