import React from 'react'
import { Link } from 'react-router-dom';

interface MaintenanceProps
{
    title?: string;
    message: string;
}

function Maintenance(props: MaintenanceProps) {
  return (
    <div className="w-full h-screen bg-[url('/maintenance.svg')] flex items-center justify-center">
        <div className='flex flex-col gap-3 text-white items-center'>
            <h1 className='text-[46px] font-bold text-center p-3'>{props.title ?? 'Aïe...'}</h1>
            <p className='text-lg font-semibold text-center p-5'>{props.message}</p>
            <Link to={'/'}>
                <span className='font-bold underline underline-offset-2'>Retourner à l'accueil</span>
            </Link>
        </div>
    </div>
  )
}

export default Maintenance