import React, { useEffect, useState } from 'react'
import { publicApi } from '../../../api/axiosapi'
import DataCard from '../components/DataCard'
import LastArticles from '../components/LastArticles'
import PieChart from '../components/PieChart'
import RecentComment from '../components/RecentComment'

interface DashboardData
{
	visits: number,
	articles: number,
	comments: number
}

function DashboardHome() {

	const [data, setData] = useState<DashboardData>({
		articles: 0,
		visits: 0,
		comments: 0
	});

	useEffect(() => {
		publicApi.get<number>('/article/count')
		.then((response) => {
			setData({...data, articles: response.data});
		});
	}, []);

  return (
    <div className='flex flex-col gap-5 w-full items-center'>
		{/** 
        <div className='flex gap-1 rounded-lg items-center w-full bg-white p-3 px-5'>
			<input type="text" className='w-full font-light text-md outline-none' placeholder='Chercher un article' />
			<i className="fa-solid fa-md fa-magnifying-glass text-[#1181e8]"></i>
		</div>
		*/}
		<div className='flex flex-col gap-5 items-center md:flex-row md:flex-wrap md:justify-center'>
			<DataCard icon='fa-eye' label='Visiteurs' primaryColor='#1181e8' secondaryColor='#e7f2fd' value='0'/>
			<DataCard icon='fa-clipboard' label='Articles' primaryColor='#ff8700' secondaryColor='#fff3e6' value={`${data.articles}`}/>
			<DataCard icon='fa-comments' label='Commentaires' primaryColor='#f94e6f' secondaryColor='#feedf1' value='0'/>
		</div>
		<div className='flex flex-col gap-5 md:flex-row'>
			<PieChart tabname='Appareils Utilisés' label='75%' value1={{label: 'Mobile', value: '25%', color:'#f72485', icon:'fa-circle-down'}} value2={{label:'Desktop', value:'75%', icon:'fa-circle-up'}} />
			<RecentComment />
		</div>
		<div className='w-full'>
			<LastArticles />
		</div>
    </div>
  )
}

export default DashboardHome