import React, { useState } from 'react'
import { Link } from 'react-router-dom'

interface NavbarProps
{
	buttonColor?: string;
}

function Navbar(props: NavbarProps) {

	const [open, setOpen] = useState(false);

  return (
	<>
		<div className='p-5 flex gap-5 items-center justify-between'>
			<span className='flex gap-2 items-center font-semibold'><img src="/logo-intercos37.png" width={50} height={10} alt="" />Intercos37</span>
			<div className='flex gap-5 hidden'>
				<ul className='flex gap-3'>
					<Link to=''><li>Home</li></Link>
					<Link to=''><li>About</li></Link>
					<Link to=''><li>Team</li></Link>
					<Link to=''><li>Testimonials</li></Link>
				</ul>
				<Link to=''>Register</Link>
			</div>
			<div className='z-20 md:hidden'>
				<div onClick={() => setOpen(!open)} className={`flex items-center justify-center rounded bg-[${props.buttonColor}]`}><i className="fa-solid fa-bars-staggered fa-xl p-4 px-2"></i></div>
				{/** Burger button displayed when mobile version and hidden when desktop version */}
			</div>
			<div className='hidden md:flex'>
				<ul className='flex flex-row gap-5 font-semibold items-center'>
					<Link to={"/"}>Accueil</Link>
					<Link to={"/offres"}>Nos offres</Link>
					<Link to={"/partenaires"}>Nos partenaires</Link>
					<Link to={'/login'}><li className='px-4 bg-[#041d32] border-[1px] font-normal border-1 text-white p-2 rounded-full hover:mb-2 transition-all'>Connexion</li></Link>
				</ul>
			</div>
		</div>
		<div className={`absolute h-screen w-full top-0 z-10 ${open ? 'visible' : 'hidden'} bg-[#041d32d2]`}>
			<div data-aos='fade-up' className='flex items-center justify-center w-full flex-col gap-10 h-full text-2xl text-white'>
				<Link to={'/'}>Accueil</Link>
				<Link to={'/offres'}>Les offres</Link>
				<Link to={'/partenaires'}>Les partenaires</Link>
				<Link to={'/login'}>Se connecter</Link>
			</div>
		</div>
	</>
  )
}

export default Navbar