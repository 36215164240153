interface DownloadButtonProps
{
    label: string;
    path: string;
}

function DownloadButton(props: DownloadButtonProps) {
  return (
    <a href={`${process.env.REACT_APP_API_URL}/${props.path}`} download={props.label} className='bg-[#284bf5] rounded-lg flex justiy-between items-center overflow-hidden'>
        <span className='text-white font-semibold text-md p-3'>{props.label}</span>
        <div className='flex items-center justify-center bg-[#1c3fe5] text-white w-12 h-full'>
            <i className="fa-solid fa-down-long"></i>
        </div>
    </a>
  )
}

export default DownloadButton